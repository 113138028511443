/* Importa la fuente desde Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=League+Gothic:wght@400&display=swap');

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.form-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    font-family: 'League Gothic', sans-serif;
}

.batman-image {
    flex: 1 1 30%;
    max-width: 450px; 
}

.batman-image img {
    width: 200%; 
    height: auto;
    margin-left: -300px;
}

.form-content {
    flex: 1 1 40%; 
    max-width: 410px; 
    padding: 25px;
    height: 750px;
    border: 2px solid white;
    border-radius: 25px;
    background: transparent;
    position: relative; 
}

.form-content h1 {
    color: white;
    text-align: center;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5); 
    font-size: 3.5rem; 
    margin-bottom: 0px; 
    font-weight: lighter;
}

.form-content p {
    color: white;
    text-align: center;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    font-size: 1.4rem; 
    margin-bottom: 20px; 
    font-weight: lighter;
    margin-top: 0px;
}

.form-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-content label {
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: lighter;
    color: white;
    align-self: flex-start; 
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5); 
}

.form-content input, .form-content textarea {
    width: 95%; 
    padding: 10px;
    border: 2px solid white;
    border-radius: 12px;
    margin-bottom: 15px; 
    background-color: transparent;
    color: white; 
}

.form-content textarea {
    height: 150px;
    resize: vertical;
}

.submit-container {
    display: flex;
    justify-content: center; 
    width: 100%; 
    margin-top: 5px;
}

.submit-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 300px; 
    height: auto;
    margin-bottom: 10px;
}

.submit-button img {
    width: 100%; 
    height: auto;
}

.legal-image {
    flex: 1 1 100%; 
    text-align: center;
    margin-top: 30px; 
}

.legal-image img {
    width: 50%; 
    height: auto;
    max-width: 400px; 
    margin: 0 auto; 
}

.back-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 24px; 
    cursor: pointer;
    font-family: 'League Gothic', sans-serif; 
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5); 
}

.mandatory-fields {
    margin-top: 20px;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5); 
}

@media (max-width: 768px) {
    .batman-image {
        display: none;
    }
    
    .batman-image img {
        width: 100%; 
        height: auto;
    }

    .form-content h1 {
        color: white;
        text-align: center;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5); 
        font-size: 2.5rem; 
        margin-bottom: 0px; 
        font-weight: lighter;
    }
    
    .form-content p {
        color: white;
        text-align: center;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        font-size: 1rem; 
        margin-bottom: 20px; 
        font-weight: lighter;
        margin-top: 0px;
    }
}