.Main-Title {
    font-family: 'League Gothic', sans-serif;
    color: white;
    font-size: 4rem;
    text-align: center;
    text-shadow: 10px 10px 2px rgba(0, 0, 0, 0.3);
  }
  
  .Node {
    justify-content: center;
  }
  
  .contenedorFAQ {
    width: 800px;
    background-color: #282828;
    margin: 20px auto;
    padding: 0px;
    border-radius: 15px;
    box-shadow: 10px 10px 1px rgba(0, 0, 0, 0.3);
    transition: height 0.3s ease;
  }
  
  .pregunta {
    font-family: 'League Gothic', sans-serif;
    font-size: 3rem;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding-top: 20px;
  }
  
  .boton {
    background-color: transparent;
    border-color: transparent;
    font-size: 2rem;
    color: white;
    font-weight: bolder;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    margin-left: 20px;
    margin-bottom: 10px;
  }
  
  .respuesta {
    font-family: 'League Gothic', sans-serif;
    font-size: 1.5rem;
    color: white;
    margin: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    padding-bottom: 30px;
  }
  
  .form-back-button {
    background: none;
    border: none;
    font-size: 40px;
    cursor: pointer;
    color: white;
  }
  
  .custom-link {
    color: yellow;
    text-decoration: none;
    font-weight: bold;
  }
  
  .custom-link:hover {
    text-decoration: underline;
  }
  
  @media only screen and (max-width: 767px) {
    .Main-Title {
      font-size: 3rem;
    }
  
    .contenedorFAQ {
      width: 300px;
    }
  
    .pregunta {
      font-size: 2.5rem;
    }
  
    .boton {
      font-size: 1.5rem;
    }
  
    .respuesta {
      font-size: 1.2rem;
    }
  }
  