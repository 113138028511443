.main-container {
  position: relative;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.save-date {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 6%;
}

.button-inscribete {
  position: absolute;
  top: 60%;
  left: 44.5%;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  width: 35%; 
}

.button-inscribete img {
  width: 100%;
  height: auto;
}

.circuitos-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
}

.circuitos-container img {
  max-width: 40%; 
  height: auto; 
  object-fit: contain;
}

.Kit-title-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px; 
}

.kit-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.kit-Entrega {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}

.kit-background {
  display: block;
  width: 105%;
  height: auto;
}

.kit-item {
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: auto;
}

.agenda-container {
  margin-top: 20px;
}

.agenda-image {
  display: block;
  width: 100%;
  height: auto;
}

.sponsor-container {
  margin-top: 20px;
}

.sponsor-image {
  display: block;
  width: 70%;
  height: auto;
}

.legale-container {
  margin-top: 60px;
  text-align: center;
}

.instagram {
  display: block;
  left: 0;
  right: 0;
  position: absolute;
  width: 64%;
  height: auto;
  margin: auto;
}

.legale-image {
  display: inline-block;
  width: 40%;
  height: auto;
  margin-top: 40px;
}

.volver-inicio {
  position: relative;
  margin-left: 85%;
}

.flecha-volver-inicio {
  background-color: transparent;
  border-color: transparent;
}

.flecha {
  font-size: 2.5rem;
  font-weight: bolder;
  color: white;
}

@media only screen and (max-width: 767px) {

  .circuitos-container {
    position: absolute;
    display: contents;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: center;
  }

  .texto-circuito {
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .circuitos {
    position: relative;
    justify-content: center;
    align-items: center;
  }
  
  .circuitos-container img {
    max-width: 40%; 
    height: auto; 
    object-fit: contain;
  }
  
  .kit-background {

    display: block;
    width: 100%;
    height: auto;
  }

  .legale-container {
    margin-top: 0px;
    text-align: center;
  }

  .instagram {
    display: block;
    left: 0;
    right: 0;
    position: absolute;
    width: 64%;
    height: auto;
    margin: auto;
    margin-top: 25px;
  }

  .legale-image {
    display: inline-block;
    width: 40%;
    height: auto;
  }
}
