@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400;700&display=swap');

.header {
  font-family: 'Bebas Neue', sans-serif;
}

.desktop-header {
  display: block;
}

.mobile-header {
  display: none;
}

.logo-background-container {
  position: relative;
  width: 100%;
  height: 150px; 
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-logo {
  max-height: 150px; 
  width: auto;
}

.navbar {
  background-color: #0d0f0e; 
  width: 100%;
}

.navbar.fixed {
  position: fixed;
  top: 0;
  z-index: 10000;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  padding: 10px 250px;
  margin: 0;
}

.nav-item a {
  color: #fff; 
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  display: inline-block;
  text-align: center;
  font-family: 'Bebas Neue', sans-serif; 
  font-weight: lighter;
  font-size: 1.5rem;
}

@media (max-width: 768px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: block;
  }

  .logo-background-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 150px;
  }

  .background-image {
    width: 100%;
    height: 200; 
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; 
  }

  .logo-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 1; 
  }

  .header-logo {
    max-height: 100px;
    width: auto;
    margin: 0 10px;
  }

  .menu-toggle {
    background-color: #0d0f0e; 
    color: #fff; 
    border: none;
    padding: 10px 20px;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1; 
    position: relative;
    width: 400px; 
    text-align: left;
  }

  .navbar {
    display: none; 
    flex-direction: column; 
    background-color: #0d0f0e; 
    position: absolute;
    top: 80%; 
    left: 0; 
    width: 200px; 
    z-index: 2; 
  }

  .menu-toggle.fixed {
    position: fixed;
    top: 0;
    z-index: 99999999;
  }

  .navbar.open {
    display: contents; 
  }

  .nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; 
    flex-direction: column;
  }

  .nav-list.fixed {
    position: fixed;
    background-color: #0d0f0e;
    top: 50px;
    z-index: 99999999;
  }


  .nav-item a {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    display: block;
    text-align: center;
  }
}
